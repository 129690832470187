import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { AddStockId, ResetStockIdList } from '../actions/pricing-box.actions';

export class PricingBoxStateModel {
  stockIdList: number[];
}

@State<PricingBoxStateModel>({
  name: 'pricingBox',
  defaults: {
    stockIdList: [] as number[],
  },
})
@Injectable()
export class PricingBoxState {
  @Selector()
  static getStockIdList(state: PricingBoxStateModel): number[] {
    return state.stockIdList;
  }

  @Action(AddStockId)
  addStockId(
    { getState, setState }: StateContext<PricingBoxStateModel>,
    { id }: { id: number }
  ): void {
    // Add the id to the list, if it's not already there
    const s = getState();

    if (!s.stockIdList.includes(id)) {
      setState({
        ...s,
        stockIdList: [...s.stockIdList, id],
      });
    }
  }

  @Action(ResetStockIdList)
  resetStockIdList({ patchState }: StateContext<PricingBoxStateModel>): void {
    patchState({
      stockIdList: [],
    });
  }
}
