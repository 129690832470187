import { ModalController } from '@ionic/angular';
import { BaseComponent } from '../components/base/base.component';

export class ModalHelper extends BaseComponent {
  constructor(public modalController: ModalController) {
    super();
  }

  /**
   * Closes the modal and returns a promise
   * that resolves to the data passed in the modal's dismiss() function
   * @param {any | boolean} [res] - any | boolean
   * @returns A promise that resolves when the modal is dismissed.
   */
  dismiss(res?: unknown | boolean): Promise<unknown | boolean> {
    return this.modalController.dismiss(res ?? false);
  }
}
