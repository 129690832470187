import { SessionStorageResponse } from '../../models/session-storage-response';

export class DeleteSession {
  static readonly type = '[SESSION] Delete Session';
}

export class SetSession {
  static readonly type = '[SESSION] Set Session';

  constructor(public session: SessionStorageResponse) {}
}
