import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { SnackService } from '../components/snack/snack.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  constructor(private snack: SnackService) {}

  /**
   * HANDLES HTTP ERRORS
   * @param error The Http Error Response
   */
  public onError(error: HttpErrorResponse | any): void {
    console.error('onError', error);

    switch (error.status) {
      case 400:
        // 400 validation error
        // eslint-disable-next-line
        return this.handle400(error);
      case 406:
        // 406 for custom errors
        // eslint-disable-next-line
        return this.handle406(error);
      default:
        this.snack.openError(undefined, undefined);
        break;
    }
  }

  private handle400(error: HttpErrorResponse): void {
    if (error.error.jsonMessage) {
      // for each key in jsonMessage, show a toast
      // eslint-disable-next-line
      Object.keys(error.error.jsonMessage).forEach((key, index) => {
        if (index !== 0)
          setTimeout(() => {
            this.snack.openError(
              undefined,
              `${key} ${error.error.jsonMessage[key]}`
            );
            // this.snack.openSnack(
            //   {
            //     title: 'Error',
            //     message: `${key} ${error.error.jsonMessage[key]}`,
            //     type: SnackBarTypes.ERROR,
            //   },
            //   4000
            // );
          }, 4500);
        else {
          this.snack.openError(
            undefined,
            `${key} ${error.error.jsonMessage[key]}`
          );
          // this.snack.openSnack(
          //   {
          //     title: 'Error',
          //     message: `${key} ${error.error.jsonMessage[key]}`,
          //     type: SnackBarTypes.ERROR,
          //   },
          //   4000
          // );
        }

        console.log(error.error.jsonMessage[key]);
      });
    }
  }

  private handle406(error: HttpErrorResponse): void {
    if (error.error.originalMessage) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      this.snack.openError(undefined, error.error.originalMessage);
    }
  }
}
