import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { AuthState } from '../store/states/auth.state';
import { SessionState } from '../store/states/session.state';
import { NotificationsState } from '../store/states/notifications.state';
import { environment } from '../../../environments/environment';
import { PricingBoxState } from '../store/states/pricing-box.state';

@NgModule({
  imports: [
    NgxsModule.forRoot([
      AuthState,
      SessionState,
      NotificationsState,
      PricingBoxState,
    ]),
    NgxsStoragePluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsLoggerPluginModule.forRoot({
      disabled: environment.production,
    }),
  ],
})
export class NgxsWrapperModule {}
