import { Token, User } from '../../models/user';

export class LogoutUser {
  static readonly type = '[AUTH] Logout User';
}

export class SetUser {
  static readonly type = '[AUTH] Set User';

  constructor(public user: User) {}
}

export class SetToken {
  static readonly type = '[AUTH] Set Token';

  constructor(public token: Token) {}
}
