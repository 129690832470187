import { Injectable } from '@angular/core';
import Pusher, { Channel } from 'pusher-js';

@Injectable({
  providedIn: 'root',
})
export class PusherService {
  pusher: Pusher;
  notificationsChannel: Channel;
  generatedDocumentChannel: Channel;
  constructor() {
    this.pusher = new Pusher('fb556d167859a1dcc012', {
      cluster: 'eu',
    });
  }

  initNotificationChannel(id: number): void {
    this.notificationsChannel = this.pusher.subscribe(`user-${id}`);
  }

  initGeneratedDocumentChannel(id: number): void {
    this.generatedDocumentChannel = this.pusher.subscribe(
      `generateDocument-${id}`
    );
    console.log('GENERATED DOCUMENT CHANNEL INITED WITH ID:', id);
  }

  subscribeToChannel(channelName: string): Channel {
    return this.pusher.subscribe(channelName);
  }
}
