// noinspection AngularMissingOrInvalidDeclarationInModule

import { Component, HostListener, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import packageJson from 'package.json';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss'],
})
export class BaseComponent implements OnDestroy {
  public ngUnsubscribe = new Subject<void>();
  public innerWidth: number;
  public version: string = packageJson.version;

  constructor() {
    this.innerWidth = window.innerWidth;
  }

  ngOnDestroy(): void {
    console.error('ONDESTRROY');
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  @HostListener('window:resize', ['$event'])
  // @debounce(100)
  onResize(): void {
    this.innerWidth = window.innerWidth;
  }
}

// /**
//  * Debounce function
//  * @param delay
//  */
// export function debounce(delay = 300): MethodDecorator {
//   return function (
//     target: any,
//     propertyKey: string,
//     descriptor: PropertyDescriptor
//   ) {
//     const timeoutKey = Symbol();
//
//     const original = descriptor.value;
//
//     descriptor.value = function (...args) {
//       clearTimeout(this[timeoutKey]);
//       this[timeoutKey] = setTimeout(() => original.apply(this, args), delay);
//     };
//
//     return descriptor;
//   };
// }
