// noinspection JSUnusedLocalSymbols

import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { PusherService } from './pusher.service';
import { User } from '../models/user';
import { SessionStorageService } from './session-storage.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { first } from 'rxjs/operators';
import { Notification } from '../models/notification';
import { Capacitor } from '@capacitor/core';
import {
  LocalNotificationSchema,
  LocalNotifications,
  ScheduleOptions,
} from '@capacitor/local-notifications';
import { PermissionStatus } from '@capacitor/local-notifications/dist/esm/definitions';
import { firstValueFrom } from 'rxjs';
import { SnackService } from '../components/snack/snack.service';
import { SnackBarTypes } from '../components/snack/snack.component';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  user: User;

  constructor(
    public platform: Platform,
    private pusher: PusherService,
    private session: SessionStorageService,
    private http: HttpClient,
    private snack: SnackService
  ) {
    this.user = this.session.getItem('userData');
  }

  /**
   * GETS NOTIFICATIONS OF THE USER
   */
  getNotificationsForUser(): Promise<Notification[]> {
    return new Promise<Notification[]>((resolve, reject) => {
      this.http
        .get(`${environment.apiUrl}notificationSelf`)
        .pipe(first())
        .subscribe(
          (data: Notification[]) => {
            resolve(data);
          },
          error => {
            reject(error);
          }
        );
    });
  }

  /**
   * SHOWS A NOTIFICATION
   * @param m
   */
  showNotification(m: Notification): void {
    if (!Capacitor.isNativePlatform()) {
      // WEB
      void this.showWebNotification(m);
    } else {
      // NATIVE
      void this.showNativeNotification(m).then(value => {
        console.log(value);
      });
    }
  }

  /**
   * SHOWS A NATIVE NOTIFICATION ON MOBILE DEVICES
   * @param m
   */
  async showNativeNotification(m: Notification): Promise<void> {
    // Schedule a single notification
    console.log('SHOW NATIVE NOTI DATA (M) (TITLE):');
    console.log(m.title);

    void LocalNotifications.checkPermissions().then((res: PermissionStatus) => {
      if (res.display !== 'granted') {
      }
    });

    const notification = {
      id: new Date().getTime(),
      title: 'Értesítés',
      body: m.title,
      foreground: true,
    } as LocalNotificationSchema;

    console.log('NOTIFICATION OBJECT: ', notification);

    await LocalNotifications.schedule({
      notifications: [notification],
    } as ScheduleOptions);
  }

  /**
   * SHOWS A WEB NOTIFICATION ON DESKTOP
   * @param m
   */
  async showWebNotification(m: Notification): Promise<void> {
    if (Notification.permission === 'denied') {
      this.snack.openSnack({
        message: m.title,
        title: 'Értesítés',
        type: SnackBarTypes.SUCCESS,
      });

      return;
    }

    if (Notification.permission === 'default') {
      await Notification.requestPermission().then(_ => {
        if (_ === 'granted') {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const notification = new Notification('Értesítés', {
            body: m.title,
          });
        }
      });
    }

    if (Notification.permission === 'granted') {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const notification = new Notification('Notification', {
        body: m.title,
      });
    }
  }

  /**
   * DELETES A NOTIFICATION
   * @param id
   */
  removeNotification(id: number): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.http
        .delete(`${environment.apiUrl}notification/${id}`)
        .pipe(first())
        .subscribe(
          () => {
            resolve();
          },
          error => {
            reject(error);
          }
        );
    });
  }

  /**
   * DELETES ALL NOTIFICATIONS
   */
  removeAllNotifications(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.http
        .delete(`${environment.apiUrl}notificationAllSelf`)
        .pipe(first())
        .subscribe(
          () => {
            resolve();
          },
          error => {
            reject(error);
          }
        );
    });
  }

  testNotification(): Promise<void> {
    return firstValueFrom(
      this.http.post<any>(`${environment.apiUrl}notification`, {
        title: 'TEST NOTIFICATION',
        type: 'info',
        userId: 1,
      })
    );
  }

  // --------------------------------------------------

  // sendPushNotificationToUser(body: SendPushBody): Promise<void> {
  //   return new Promise((resolve, reject) => {
  //     this.http.post(`${environment.cfUrl}sendNotificationToUserId`,
  //       { userId: body.userId, message: body.message, title: body.title },
  //     )
  //       .pipe(first())
  //       .subscribe(() => {
  //         resolve();
  //       }, (error) => reject(error));
  //   });
  // }
}
