import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import {
  SessionStorageResponse,
  UserData,
} from '../../models/session-storage-response';
import { DeleteSession, SetSession } from '../actions/session.actions';

export class SessionStateModel {
  session: SessionStorageResponse;
}

@State<SessionStateModel>({
  name: 'session',
  defaults: {
    session: {} as SessionStorageResponse,
  },
})
@Injectable()
export class SessionState {
  @Selector()
  static getUser(state: SessionStateModel): UserData {
    return state.session.userData;
  }

  @Selector()
  static getSession(state: SessionStateModel): SessionStorageResponse {
    return state.session;
  }

  @Action(SetSession)
  setSession(
    { getState, setState }: StateContext<SessionStateModel>,
    session: { session: SessionStorageResponse }
  ): void {
    const s = getState();

    setState({
      ...s,
      session: session.session,
    });
  }

  @Action(DeleteSession)
  deleteSession({ patchState }: StateContext<SessionStateModel>): void {
    patchState({
      session: {} as SessionStorageResponse,
    });
    localStorage.clear();
    sessionStorage.clear();
  }
}
