import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SnackComponent } from './snack.component';
import { MatIconModule } from '@angular/material/icon';
import { IonicModule } from '@ionic/angular';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [SnackComponent],
  imports: [
    CommonModule,
    MatIconModule,
    IonicModule,
    MatButtonModule,
    MatSnackBarModule,
    TranslateModule.forChild(),
  ],
})
export class SnackModule {}
