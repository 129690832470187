import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { NgxsWrapperModule } from './shared/modules/ngxs.wrapper.module.';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {
  NgxPermissionsModule,
  NgxPermissionsService,
  NgxRolesService,
} from 'ngx-permissions';
import { AvatarModule } from 'ngx-avatars';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthInterceptor } from './shared/services/auth-interceptor.service';
import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { DocumentScanner } from '@ionic-native/document-scanner/ngx';
import { LottieCacheModule, LottieModule } from 'ngx-lottie';
import player, { LottiePlayer } from 'lottie-web';
import { SnackModule } from './shared/components/snack/snack.module';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DirectivesModule } from './shared/directives/directives.module';
import {
  DateAdapter,
  MatNativeDateModule,
  MatOptionModule,
} from '@angular/material/core';
import { MyDateAdapter } from './shared/date-adapters/my-date-adapter';
import {
  NgxMatDateAdapter,
  NgxMatNativeDateModule,
} from '@angular-material-components/datetime-picker';
import { NgxMyDateAdapter } from './shared/date-adapters/ngx-my-date-adapter';
import { UpdateDateHttpInterceptor } from './shared/interceptors/date-interceptor';
import { TranslatorService } from './shared/services/translator.service';
import { MatSelectCountryModule } from '@angular-material-extensions/select-country';
import localeHU from '@angular/common/locales/hu';
import { NgOptimizedImage, registerLocaleData } from '@angular/common';
import { InitializerService } from './shared/services/initializer.service';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ManualInnerBoxModalComponent } from './shared/components/manual-inner-box-modal/manual-inner-box-modal.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';

registerLocaleData(localeHU);

/**
 * Note we need a separate function as it's required
 * by the AOT compiler.
 */
export function playerFactory(): LottiePlayer {
  return player;
}

/**
 * AoT requires an exported function for factories
 * @param http
 * @constructor
 */
// export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
//   return new TranslateHttpLoader(http);
// }

/**
 * HTTP Loader for Translations
 * @param http
 */
export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
}

const interceptors = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: UpdateDateHttpInterceptor,
    multi: true,
  },
];

const dateAdapters = [
  {
    provide: DateAdapter,
    useClass: MyDateAdapter,
  },
  {
    provide: NgxMatDateAdapter,
    useClass: NgxMyDateAdapter,
  },
];

@NgModule({
  declarations: [AppComponent, ManualInnerBoxModalComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot({
      mode: 'md',
    }),
    AppRoutingModule,
    NgxsWrapperModule,
    HttpClientModule,
    MatNativeDateModule,
    NgxPermissionsModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
      defaultLanguage: 'hu',
    }),
    AvatarModule.forRoot({
      colors: [
        '#7068B4',
        '#E5AF55',
        '#179884',
        '#EC524B',
        '#EC8B46',
        '#3A69A6',
      ],
    }),
    GooglePlaceModule,
    // AgmCoreModule.forRoot({
    //   apiKey: environment.googleMapsApiKey,
    //   libraries: ['places'],
    // }),
    LottieModule.forRoot({ player: playerFactory }),
    LottieCacheModule.forRoot(),
    SnackModule,
    DirectivesModule,
    NgxMatNativeDateModule,
    MatSelectCountryModule.forRoot('hu'),
    NgxChartsModule,
    MatButtonModule,
    MatIconModule,
    NgOptimizedImage,
    MatInputModule,
    FormsModule,
    MatOptionModule,
    MatSelectModule,
    ReactiveFormsModule,
  ],
  providers: [
    ...interceptors,
    ...dateAdapters,
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (
        ds: InitializerService,
        ps: NgxPermissionsService,
        rs: NgxRolesService
      ) =>
        function () {
          return ds
            .init()
            .then(data => {
              console.log('permissions loaded', data);
              // todo backend need to give role array
              // rs.addRole('Head Buyer', () => true);
              return ps.loadPermissions(data);
            })
            .catch(() => {
              return null;
            });
        },
      deps: [InitializerService, NgxPermissionsService, NgxRolesService],
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'hu' },
    LocalNotifications,
    DocumentScanner,
    TranslatorService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
