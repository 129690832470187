import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription, fromEvent } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CodeReaderService {
  codeRead = new BehaviorSubject<string | null>(null);

  get codeRead$(): Observable<string | null> {
    return this.codeRead.asObservable();
  }

  readerCode = '';
  readingCode = false;

  // Hold a reference to the subscription.
  private keyboardSub?: Subscription;

  listenForCodes = fromEvent(window, 'keypress').pipe(
    tap((e: any) => {
      // * Usually scanners throw an 'Enter' key at the end of read

      if (e.keyCode === 13) {
        if (this.readerCode.length > 3) {
          console.log('SERVICE:', this.readerCode);
          this.codeRead.next(this.readerCode);
          this.codeRead.next(null);
          this.readerCode = '';
        }
      } else {
        // * While this is not an 'enter' it stores the every key
        if (e.key === 'ö') {
          this.readerCode += '0';
        } else this.readerCode += e.key;
      }

      // * Run a timeout of 3000ms at the first read and clear everything
      if (!this.readingCode) {
        this.readingCode = true;

        setTimeout(() => {
          this.readerCode = '';
          this.readingCode = false;
        }, 3000);
        // 200 works fine for me but you can adjust it
      }
    })
  );

  constructor() {
    // Subscribe to the property or use the async pipe.
    // Remember to unsubscribe when you are done if you don't use the async pipe.
    this.keyboardSub = this.listenForCodes.subscribe();
  }

  unsubscribe(): void {
    this.keyboardSub?.unsubscribe();
  }

  resetValue(): void {
    this.codeRead.next(null);
  }
}
