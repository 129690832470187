import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { firstValueFrom } from 'rxjs/internal/firstValueFrom';
import { LogoutUser } from '../store/actions/auth.actions';
import { DeleteSession } from '../store/actions/session.actions';
import { Store } from '@ngxs/store';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class InitializerService {
  constructor(
    private http: HttpClient,
    private store: Store,
    private router: Router
  ) {}

  /**
   * Returns the user's Permission
   */
  getUserPermissions = (): Promise<string[]> => {
    return firstValueFrom(
      this.http.get<string[]>(`${environment.apiUrl}getUserPermissions`)
    ).catch((error: HttpErrorResponse) => {
      if (error === undefined) {
        error = new HttpErrorResponse({
          status: 406,
        });
      }
      if (error.status === 401) this.logout();
      return Promise.reject(error);
    });
  };

  async init(): Promise<string[]> {
    return await this.getUserPermissions();
  }

  logout(): void {
    localStorage.clear();
    sessionStorage.clear();
    this.store.dispatch(new LogoutUser());
    this.store.dispatch(new DeleteSession());
    void this.router.navigate(['/auth/login'], { replaceUrl: true });
  }
}
