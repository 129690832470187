import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { SnackBarData, SnackBarTypes, SnackComponent } from './snack.component';

@Injectable({
  providedIn: 'root',
})
export class SnackService {
  currentSnackData: SnackBarData;

  constructor(private snackBar: MatSnackBar, private zone: NgZone) {}

  openSuccess(title?: string, message?: string): void {
    this.openSnack(
      {
        title: title ?? 'Siker',
        message: message ?? 'Sikeres módosítás',
        type: SnackBarTypes.SUCCESS,
      },
      1500
    );
  }

  openError(title?: string, message?: string, duration?: number): void {
    this.openSnack(
      {
        title: title ?? 'Hiba',
        message: message ?? 'Nem ismert hiba történt',
        type: SnackBarTypes.ERROR,
      },
      duration ?? 1500
    );
  }

  openSnack(snackData: SnackBarData, duration?: number): void {
    this.zone.run(() => {
      const config = new MatSnackBarConfig();

      config.panelClass = ['custom-snackbar'];
      this.currentSnackData = snackData;
      config.duration = duration ?? 3000;

      this.snackBar.openFromComponent(SnackComponent, config);
    });
  }
}
