import { NgModule } from '@angular/core';
import { ScrollbarThemeDirective } from './scrollbar.directive';
import { CommonModule } from '@angular/common';
import { TruncateTooltipDirective } from './truncate-tooltip.directice';

@NgModule({
  declarations: [ScrollbarThemeDirective, TruncateTooltipDirective],
  imports: [CommonModule],
  exports: [ScrollbarThemeDirective, TruncateTooltipDirective],
})
export class DirectivesModule {}
