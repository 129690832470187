import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, firstValueFrom } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ErrorService } from './error.service';
import { Warehouse } from '../models/warehouse';
import { PaginatedData } from '../models/paginated-data';
import { AllTransfers } from '../models/all-transfers';
import { ProductStock } from '../models/product-stock';
import { SnackService } from '../components/snack/snack.service';

@Injectable({
  providedIn: 'root',
})
export class WarehousesService {
  private warehouses: BehaviorSubject<Warehouse[]>;

  get warehouses$(): Observable<Warehouse[]> {
    return this.warehouses.asObservable();
  }

  constructor(
    private http: HttpClient,
    private error: ErrorService,
    private snack: SnackService
  ) {
    this.warehouses = new BehaviorSubject<Warehouse[]>([]);
    // --
    void this.getWarehouses();
  }

  getWarehouses = async (): Promise<Warehouse[]> => {
    try {
      const warehouses = await firstValueFrom(
        this.http.get<Warehouse[]>(`${environment.apiUrl}warehouses`)
      );
      this.warehouses.next(warehouses);
      return warehouses;
    } catch (err) {
      this.error.onError(err);
      return await Promise.reject(err);
    }
  };

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  createNewInternalTransfer = async (data: any): Promise<any> => {
    try {
      return await firstValueFrom(
        this.http.post<any>(
          `${environment.apiUrl}createNewInternalTransfer`,
          data
        )
      );
    } catch (err) {
      this.error.onError(err);
      return await Promise.reject(err);
    }
  };
  manualInnerBox = (data): Promise<ProductStock> => {
    return firstValueFrom(
      this.http.post<ProductStock>(
        `${environment.apiUrl}addItemsToInternalBoxSelection`,
        data
      )
    )
      .then((res: any) => {
        this.snack.openSuccess(undefined, res.originalMessage);

        return Promise.resolve(res);
      })
      .catch(err => {
        this.error.onError(err);
        return Promise.reject(err);
      });
  };

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  createNewStockWatcher = async (data: any): Promise<any> => {
    try {
      return await firstValueFrom(
        this.http.post<any>(`${environment.apiUrl}createItemQytCheck`, data)
      );
    } catch (err) {
      this.error.onError(err);
      return await Promise.reject(err);
    }
  };

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  deleteStockWatcher = async (id: number): Promise<any> => {
    try {
      return await firstValueFrom(
        this.http.delete<any>(`${environment.apiUrl}deleteItemQytCheck/${id}`)
      );
    } catch (err) {
      this.error.onError(err);
      return await Promise.reject(err);
    }
  };
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  updateNewStockWatcher = async (data: any, id: number): Promise<any> => {
    try {
      return await firstValueFrom(
        this.http.post<any>(
          `${environment.apiUrl}updateItemQytCheck/${id}`,
          data
        )
      );
    } catch (err) {
      this.error.onError(err);
      return await Promise.reject(err);
    }
  };

  listAllInternalTransfer = (
    pageSize?: number,
    filterString?: string
  ): Promise<PaginatedData<any>> => {
    let url = 'listAllInternalTransfer';
    // if we have filterString adding them to url as query param
    if (filterString) url += filterString;

    //adding pageSize as query param with correct tag
    if (pageSize)
      url += filterString ? `&pageSize=${pageSize}` : `?pageSize=${pageSize}`;
    try {
      return firstValueFrom(this.http.get<any>(`${environment.apiUrl}${url}`));
    } catch (err) {
      this.error.onError(err);
      return Promise.reject(err);
    }
  };

  getStockWatchers = (
    pageSize?: number,
    filterString?: string
  ): Promise<PaginatedData<any>> => {
    let url = 'getAllInventoryCheck';
    // if we have filterString adding them to url as query param
    if (filterString) url += filterString;

    //adding pageSize as query param with correct tag
    if (pageSize)
      url += filterString ? `&pageSize=${pageSize}` : `?pageSize=${pageSize}`;
    try {
      return firstValueFrom(this.http.get<any>(`${environment.apiUrl}${url}`));
    } catch (err) {
      this.error.onError(err);
      return Promise.reject(err);
    }
  };

  getDeliveriesNextPage(
    path: string,
    pageIndex: number,
    pageSize: number,
    filter?: string
  ): Promise<PaginatedData<any>> {
    if (!path.includes('https')) path = path.replace('http', 'https');
    return firstValueFrom(
      this.http.get<PaginatedData<any>>(
        `${path}?page=${pageIndex}&pageSize=${pageSize}${filter ?? ''}`
      )
    );
  }
}
