import { Token, User } from '../../models/user';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { LogoutUser, SetToken, SetUser } from '../actions/auth.actions';

export class AuthStateModel {
  userData: User;
  token: Token;
}

@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    userData: {} as User,
    token: {} as Token,
  },
})
@Injectable()
export class AuthState {
  @Selector()
  static getUser(state: AuthStateModel): User {
    return state.userData;
  }

  @Selector()
  static getToken(state: AuthStateModel): Token {
    return state.token;
  }

  @Action(SetUser)
  setUser(
    { patchState }: StateContext<AuthStateModel>,
    { user }: SetUser
  ): void {
    patchState({
      userData: user,
    });
  }

  @Action(SetToken)
  setToken(
    { patchState }: StateContext<AuthStateModel>,
    { token }: SetToken
  ): void {
    patchState({
      token,
    });
  }

  @Action(LogoutUser)
  logoutUser({ patchState }: StateContext<AuthStateModel>): void {
    localStorage.clear();
    sessionStorage.clear();
    patchState({
      userData: {} as User,
      token: {} as Token,
    });
  }
}
