import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SnackService } from '../../../../../shared/components/snack/snack.service';
import { ErrorService } from '../../../../../shared/services/error.service';
import { SelectingItemDetails } from '../../../../../shared/models/selecting-item-details';
import { firstValueFrom } from 'rxjs';
import { environment } from '../../../../../../environments/environment';
import {
  ISelectingUpdateItemByCode,
  ISelectingUpdateItemByCodeResponse,
} from '../../../../../shared/models/selecting-update-item-by-code-response';

@Injectable({
  providedIn: 'root',
})
export class SelectingService {
  constructor(
    private http: HttpClient,
    private snack: SnackService,
    private error: ErrorService
  ) {}

  getSelectingItemDetailsByQRCode(
    qrCode: string
  ): Promise<SelectingItemDetails> {
    return firstValueFrom(
      this.http.post<SelectingItemDetails>(
        `${environment.apiUrl}getItemByQrCode`,
        { qrCode }
      )
    ).catch(err => {
      this.error.onError(err);

      return Promise.reject(err);
    });
  }

  updateItemByQrCode(
    itemQRCodes: string[],
    qrCode: string
  ): Promise<ISelectingUpdateItemByCodeResponse> {
    return firstValueFrom(
      this.http.put(`${environment.apiUrl}updateItemByQrCode`, {
        items: itemQRCodes,
        qrCode,
      })
    )
      .then((res: ISelectingUpdateItemByCode) => {
        const { jsonMessage } = res;

        return Promise.resolve(jsonMessage);
      })
      .catch(err => {
        this.error.onError(err);
        return Promise.reject(err);
      });
  }
}
