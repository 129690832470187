export class SetNotifications {
  static readonly type = '[NOTIFICATIONS] Set Notifications';
}

export class StartListeningForNotifications {
  static readonly type = '[NOTIFICATIONS] Start Listening For Notifications';

  // User ID
  constructor(public id: number) {}
}

export class RemoveNotification {
  static readonly type = '[NOTIFICATIONS] Remove Notification';

  constructor(public id: number) {}
}

export class RemoveAllNotifications {
  static readonly type = '[NOTIFICATIONS] Remove ALL Notifications';
}
