import { Component, HostBinding } from '@angular/core';
import { OverlayContainer } from '@angular/cdk/overlay';
import {
  BackgroundColorOptions,
  StatusBar,
  Style,
} from '@capacitor/status-bar';
import { Capacitor } from '@capacitor/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { AuthService } from './shared/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { TranslatorService } from './shared/services/translator.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  @HostBinding('class') componentCssClass;
  isStatusBarLight = true;

  constructor(
    public overlayContainer: OverlayContainer,
    public authService: AuthService,
    private translate: TranslateService,
    private translator: TranslatorService
  ) {
    if (Capacitor.isNativePlatform()) {
      void this.setLightStatusBar();
      void SplashScreen.hide();
    }

    translate.addLangs(['hu', 'en']);
    translate.setDefaultLang('hu');

    translator.currentLanguage.subscribe(lang => {
      translate.use(lang);
    });
  }

  async setLightStatusBar(): Promise<void> {
    await StatusBar.setStyle({
      style: Style.Light,
    });

    const options: BackgroundColorOptions = { color: '#ffffff' };

    await StatusBar.setBackgroundColor(options);
  }
}

// region old dark theme initialization

// prefersDark = window.matchMedia('(prefers-color-scheme: dark)');

// this.prefersDark.matches ? this.setDarkStatusBar() : this.setLightStatusBar();

// this.toggleDarkTheme(this.prefersDark.matches);
// this.prefersDark.addListener((mediaQuery) => this.toggleDarkTheme(mediaQuery.matches));

// Add or remove the "dark" class based on if the media query matches
// eslint-disable-next-line
// toggleDarkTheme(shouldAdd: any): void {
//   // false - light
//   // true - dark
//   if (Capacitor.getPlatform() === 'ios' || Capacitor.getPlatform() === 'android') {
//     shouldAdd ? this.setDarkStatusBar() : this.setLightStatusBar();
//   }
//
//   document.body.classList.toggle('dark-theme', shouldAdd);
// }

// eslint-disable-next-line
// onSetTheme(theme: any): void {
//   this.overlayContainer.getContainerElement().classList.add(theme);
//   this.componentCssClass = theme;
// }

// endregion old dark theme initialization
