import {Component, OnInit} from '@angular/core';
import {ModalHelper} from "../../classes/modal-helper";
import {ModalController} from "@ionic/angular";
import {filter, takeUntil} from "rxjs/operators";
import {CodeReaderService} from "../../services/code-reader.service";
import {SelectingItemDetails} from "../../models/selecting-item-details";
import {SnackService} from "../snack/snack.service";
import {
  SelectingService
} from "../../../pages/warehouse-operations/pages/selection/selection-selecting/selecting.service";
import {FormControl, Validators} from "@angular/forms";
import {WarehousesService} from "../../services/warehouses.service";
import {Warehouse} from "../../models/warehouse";

@Component({
  selector: 'app-manual-inner-box-modal',
  templateUrl: './manual-inner-box-modal.component.html',
  styleUrls: ['./manual-inner-box-modal.component.scss']
})
export class ManualInnerBoxModalComponent extends ModalHelper implements OnInit{
  items: SelectingItemDetails[] = [];
  box: string;
  warehouse = new FormControl<number>(null, [Validators.required]);
  warehouses!: Warehouse[];

  constructor(
    public modalController: ModalController,
    private codeRead: CodeReaderService,
    private snack: SnackService,
    private selectingService: SelectingService,
    private warehouseService: WarehousesService
  ) {
    super(modalController);
  }
  async ngOnInit(): Promise<void> {
    this.startScan();
    this.warehouses = await this.warehouseService.getWarehouses();
  }

  startScan = (): void => {
    this.codeRead.codeRead$
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter(code => code != null)
      )
      .subscribe((code: string) => {
        // Get the first 2 characters of the code
        const codeType = code.slice(0, 2);

        // EP - package
        // II - product
        // AT - attribute

        switch (codeType) {
          // case 'EP':
          //   void this.getBoxDetails(code);
          //   break;
          case 'II':
            void this.getItemDetails(code);
            break;

          case 'IP':
            this.box = code;
            void this.save(code);
            break;
        }
      });
  };

  getItemDetails = async (code: string): Promise<void> => {
    if (this.items.some(i => i.qrCode === code)) {
      this.snack.openError(undefined, 'Ezt a terméket már korábban beolvastad');
      return;
    }

    const res = await this.selectingService.getSelectingItemDetailsByQRCode(
      code
    );

    console.log(res);

    // If not in items, push
    if (!this.items.find(item => item === res)) {
      this.items.push(res);
    }
  };

  removeItem = (stock: SelectingItemDetails): void => {
    this.items = this.items.filter(i => i.qrCode !== stock.qrCode);
  };

  async save(code: string): Promise<void> {
    if (this.items.length <= 0 ) return;
    const data = {
      stockIdList: this.items.map(i => i.id),
      qrCode: code,
      warehouseId: this.warehouse.value,
    }
    console.log(data);
    const res = await this.warehouseService.manualInnerBox(data);
    if (res) {
      void this.dismiss(res);
    }
  }
}
